<template>
  <v-card>
    <v-card-title> Create Print Brochure </v-card-title>
    <v-card-text>
      <v-btn color="success" class="mr-2" @click.stop="downloadCatalog()">
        Download catalog of all images
        <v-icon class="ml-4">
          mdi-download
        </v-icon>
      </v-btn>
      <v-radio-group
      v-model="type"
      row
      @change="setType"
    >
      <v-radio
        label="Artists Brochure"
        value="artist"
      ></v-radio>
      <v-radio
        label="Regional Brochure"
        value="region"
      ></v-radio>
      <v-radio
        label="Latest Images"
        value="latest"
      ></v-radio>
    </v-radio-group>
      <div class="d-flex mt-4" v-if="filteredGiclees && type === 'artist'">
        <v-autocomplete
          class="mr-2"
          v-model="selectedArtist"
          :items="artists"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          item-text="artist"
          item-value="artistCode"
          :label="'Artist'"
        ></v-autocomplete>
      </div>
      <div class="d-flex mt-4" v-if="regions.length && type === 'region'">
        <v-autocomplete
          class="mr-2"
          v-model="selectedRegion"
          :items="regions"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          :label="'Regions'"
        ></v-autocomplete>
      </div>
      <v-btn v-if="selectedArtist" color="success" @click.stop="downloadCatalog(selectedArtist)">
          Download Artist Brochure
          <v-icon class="ml-4">
            mdi-download
          </v-icon>
      </v-btn>
      <v-card-text v-if="loading" class="mt-4 text-center">
        <h2 class="mt-4 mb-4 text-center">
          Please wait while we generate the brochure
        </h2>
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="green"
        ></v-progress-circular>
      </v-card-text>
      <h3 class="my-2" v-if="filteredGiclees.artworks && !loading">Note: Artwork images are still being sorted out - there are some mismatches / ommissions. Current image column is only there for sanity check until all are confirmed</h3>
      <v-data-table
        v-if="filteredGiclees.artworks && !loading"
        :items="filteredGiclees.artworks"
        item-key="artwork"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 250],
        }"
        dense
      >
      <template v-slot:item.gicleeBrochure="{ item }">
        <v-checkbox @change="setGicleeBrochure(item)" v-model="item.gicleeBrochure" />
      </template>
      <template v-slot:[`item.artworkImage`]="{ item }">
        <div class="d-flex">
        <v-img
          height="100px"
          contain
          :src="`${imageUrl}/100px/${item.artworkImage}`"
        ></v-img>
      </div>
      </template>
      <template v-slot:[`item.thumb`]="{ item }">
        <div class="d-flex">
        <v-img
          height="100px"
          contain
          :src="`${imageUrl}/artwork-images/100px/${item.artworkCode}-PRINT.jpg`"
        ></v-img>
      </div>
      </template>
    </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ArtistApi from "@/api/admin/artist";
import ArtworkApi from "@/api/admin/artwork";
import ProductApi from "@/api/admin/product"

export default {
  data() {
    return {
      type: null,
      imageUrl: process.env.VUE_APP_IMG_URL,
      selectedArtist: null,
      selectedRegion: null,
      regionalGiclees: null,
      artistGiclees: null,
      giclees: [],
      loading: false,
      headers: [
        // { text: "Current Image", value: "artworkImage" },
        { text: "New Image", value: "thumb" },
        { text: "Title", value: "title" },
        { text: "Artwork", value: "artworkCode" },
        { text: "Collection", value: "artistCollection" },
        { text: "Sales", value: "sales" },
        { text: "Giclee Brochure", value: "gicleeBrochure" }
      ],
    };
  },
  async created() {
    ArtistApi.loadRegionalGiclees().then((res) => {
      this.regionalGiclees = res.data
    })
    ArtistApi.loadArtistGiclees({ artistCodes: [] }).then((res) => {
      this.artistGiclees = res.data;
    });
  },
  computed: {
    filter() {
      const obj = {};
      this.selectedArtist && (obj.artistCode = this.selectedArtist);
      this.selectedRegion && (obj.region = this.selectedRegion);
      return obj;
    },
    filteredGiclees() {
        return this.selectedArtist || this.selectedRegion ? _.filter(this.giclees, this.filter)[0] : [];
    },
    artists() {
      return _.orderBy(_.uniq(
        this.giclees.map((a) => {
          return { artist: a.artist, artistCode: a.artistCode };
        })
      ), ['artist'],['asc'] )
    },
    regions() {
      return this.giclees.map((a) => {
        return a.region
      }
    )},
  },
  methods: {
    async setType(evt) {
      this.giclees = []
      switch (evt) {
        case 'region' :
          this.selectedArtist = null
          this.giclees = this.regionalGiclees
        break
        case 'artist' :
          this.selectedRegion = null
          this.giclees = this.artistGiclees
        break
      }
    },
    async downloadCatalog(artistCode) {
      this.loading = true
      // const HOST = process.env.NODE_ENV === 'development' ? `ws://localhost:${process.env.VUE_APP_PORT}` : location.origin.replace(/^http/, 'ws')
      // const connection = new WebSocket(HOST)
      // connection.addEventListener('open', function (event) {
      //   connection.send(12345)
      // })
      // connection.onmessage = (event) => {
      //   const payload = JSON.parse(event.data)
      //   switch (payload.message) {
      //     case 'progress':
      //       this.progress = payload.data
      //       break
      //     case 'complete':
      //       connection.close()
      //       this.processing = false
      //       this.uploadSuccess = true
      //       break
      //     case 'stats':
      //       this.uploadStats = payload.data
      //       break
      //     case 'validationErrors':
      //       this.processing = false
      //       this.validationErrors = payload.data
      //       break
      //     default:
      //       break
      //   }
      //   // if (event.data.message === 'complete')
      //   //   this.progress = event.data
      // }
      // const HOST = process.env.NODE_ENV === 'development' ? `ws://localhost:8080` : location.origin.replace(/^http/, 'ws')
      // const connection = new WebSocket(HOST)
      // connection.send(12345)
      // connection.addEventListener('open', function (event) {
      //   connection.send(12345)
      // })
      // connection.onmessage = (event) => {
      //   const payload = JSON.parse(event.data)
      //   console.log(payload)
      //   // if (event.data.message === 'complete')
      //   //   this.progress = event.data
      // }
      ProductApi.getCatalogPDF({artistCodes: artistCode ? [artistCode] : []}).then((results) => {
        this.loading = false
      })
    },
    async setGicleeBrochure ({artworkCode, gicleeBrochure}) {
      ArtworkApi.updateArtwork({ artworkCode, gicleeBrochure }).then((res) => {
      console.log(res)
    });
    console.log(value)
    }
  }

};
</script>
